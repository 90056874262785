import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";
import routes from "../routes";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  display: flex;
  transform-origin: bottom right;
  transform: rotate(-90deg) translateX(50%);
  grid-gap: 80px;
  z-index: 10;
`;
const StickyItem = styled.div`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 8px 16px 4px 16px;
  a {
    color: ${({ color }) => color};
  }
`;
const Speaker = styled.div`
  background: ${({ background }) => background};
  padding: 8px 8px 8px 8px;

  cursor: pointer;
  position: absolute;
  right: -64px;
  img {
    transform: rotate(90deg);
    ${({ darkMode }) =>
      darkMode &&
      `
    filter: invert(100%)
      `}
  }
`;

const StickySideBar = ({ copies, showSpeaker, scrollToSpeaker }) => {
  const { region, theme } = useLanguage();

  return (
    <Wrapper>
      <Link to={`/${region}/${routes.inspireMe}`}>
        <StickyItem
          background={theme.stickyBackground}
          color={theme.stickyTextColor}
        >
          {copies.inspire_me}
        </StickyItem>
      </Link>
      {showSpeaker && (
        <Speaker
          onClick={scrollToSpeaker}
          background={theme.stickyBackground}
          darkMode={theme.darkMode}
        >
          <img
            src="/icon/icon_spotify.png"
            width="16px"
            height="16px"
            alt="spotify"
          />
        </Speaker>
      )}
    </Wrapper>
  );
};

export default StickySideBar;
