import styled from "styled-components";
import { BREAKPOINTS } from "../contexts/languageContext";
import Container from "./Container";
import { mdBreakpoint, mobileBreakpoint } from "./theme";

const HalfHalfGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
export const PageHeader = styled.div`
  font-size: min(120px, 9vw);
  line-height: min(120px, 9vw);
  flex: 0.7;
  padding-left: 32px;
  // @media (max-width: ${mdBreakpoint}) {
  //   font-size: 96px;
  // }
  @media (max-width: ${mobileBreakpoint}) {
    font-size: max(72px, 12vw);
    line-height: max(72px, 12vw);
    padding-left: 0;
    margin-top: 36px;
  }
`;

export const PageWrapper = styled(Container)`
  margin-top: 24px;
  padding: 64px 0 36px 0;
  color: ${({ color }) => color};
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0;
  }
`;
export const AbsoluteWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 35vh;
  ${({ left }) =>
    left &&
    `left: 0;
  `}
  ${({ right }) =>
    right &&
    `right: 0;
  
  `};
  @media (max-width: ${mobileBreakpoint}) {
    top: 52vh;
  }
`;

export const NavigationWrapper = styled(Container)`
  position: relative;
`;
export const LeftNavigation = styled.div`
  position: absolute;
  // top: 50%;
  transform: translateX(-100%) translateY(0);
  padding: 0 24px;
  @media (max-width: ${BREAKPOINTS.desktop + 300}px) {
    transform: translateX(0);
  }
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 4px;
    top: 64%;
  }
`;

export const RightNavigation = styled.div`
  position: absolute;
  // top: 50%;
  padding: 0 24px;
  transform: translate(0);
  @media (max-width: ${BREAKPOINTS.desktop + 300}px) {
    transform: translateX(-100%);
  }
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 4px;
    top: 64%;
  }
`;

export const HeroSection = styled(HalfHalfGrid)`
  margin-top: 24px;
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 16px;
  }
`;
export const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteHeader = styled.h2`
  font-size: max(52px, 4vw);
  text-align: ${({ textAlign }) => textAlign || "right"};
  padding: 0 36px;
  width: 100%;
  font-weight: 400;
  margin: 0;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 42px;
    padding: 0 40px;
    width: 100%;
    margin: 24px 0 0 0;
    direction: rtl;
  }
`;

export const LastQuoteHeader = styled.div`
  font-size: min(70px, 5vw);
  text-align: ${({ textAlign }) => textAlign || "right"};
  padding: 0 64px;
  width: 100%;
  font-weight: 400;
  margin: 0;
  text-align: center;
  padding: 0;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 24px;
    width: 100%;
    margin: 24px 0 0 0;

    text-align: center;
    font-size: 40px;
    margin-top: 48px;
  }
`;

export const SectionOne = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 96px 0;
  padding-left: 32px;
  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 1fr;
    padding-left: 16px;
    & > div:first-of-type {
      width: 60vw;
    }
    margin-bottom: 32px;
  }
`;

export const SectionHeader = styled.h2`
  margin: 0;
  font-size: 36px;
  font-weight: 400;
  justify-content: center;
  ${({ textAlign }) =>
    textAlign &&
    `
  text-align: ${textAlign};
  `}
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 20px;
  }
`;

export const AdditionalQuote = styled.div`
  font-size: 36px;
  padding-left: 120px;
  align-self: end;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 20px;
    padding: 0;
    margin-bottom: 48px;
  }
`;

export const FourSectionKolQuote = styled.div`
  flex: 1;
  display: flex;
  align-items: end;
`;
export const FourSectionOneContent = styled.div`
  // padding: 48px 96px 96px 96px;
  padding: 48px 0 96px 96px;
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 24px 48px 16px 0;
    margin-top: 24px;
  }
`;

export const FourSectionTwo = styled.div`
  display: grid;
  margin-top: -120px;
  grid-template-columns: 55% 35% 10%;
  & > div:first-of-type {
    align-self: center;
  }
  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 35% 55% 10%;
    margin-top: 0;
    margin-bottom: 48px;
  }
`;

export const FourSectionTwoContent = styled.div`
  padding: 48px;
  margin-top: -48px;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0;
    margin-top: 0;
  }
`;

export const FourSectionThreeContentQuote = styled(SectionHeader)`
  padding: 0 64px 0 120px;
  font-size: 32px;
`;
export const AdditionalQuote2 = styled.div`
  padding: 0 64px 0 120px;
  font-size: 32px;
  text-align: left;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 20px;
    padding: 0 40px;
    text-align: center;
  }
`;

export const FourSectionThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    grid-gap: 0;
    flex-direction: column;
    & > div:first-of-type {
      width: 75vw;
      align-self: start;
    }
    & ${SectionHeader} {
      font-size: 40px;
    }
  }
`;

export const ExploreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0 120px 0;
  @media (max-width: ${mobileBreakpoint}) {
    margin: 48px 16px;
  }
`;

export const JeanPanelWrapper = styled(HalfHalfGrid)`
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
  }
`;
export const JeanContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
  justify-content: center;
  align-items: center;
  padding: 48px;
  ${({ dir, borderColor }) =>
    dir !== "left"
      ? `
  border-right: 1px solid ${borderColor || "#fff"};
`
      : `
border-left: 1px solid ${borderColor || "#fff"};
`}
  @media (max-width: ${mobileBreakpoint}) {
    padding: 24px;
    border: none;
  }
`;
export const JeanHeader = styled.h1`
  font-size: 36px;
  text-align: center;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 30px;
  }
`;
export const JeanDescription = styled.div`
  font-size: 24px;
  text-align: center;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 22px;
  }
`;

export const ProductWrapper = styled.div`
  padding: 24px 0;
`;

export const ShopTheLookPanelWrapper = styled.div`
  margin: 48px 0;
`;
export const ShopTheLookHeader = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin: 96px 0 48px 0;
`;

export const ShopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px;
`;

export const Centered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TwoSectionOneContent = styled.div`
  padding: 48px 0;
  align-self: end;
  text-align: left;
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 32px;
    // padding: 24px 0 16px 48px;
    & > ${SectionHeader} {
      text-align: left;
      padding-left: 48px;
      padding-right: 32px;
    }
  }
`;

export const TwoContentGrid = styled(HalfHalfGrid)`
  margin-top: 64px;
  @media (max-width: ${mobileBreakpoint}) {
    & > div:first-of-type {
      padding-left: 48px;
    }
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    & ${SectionHeader} {
      font-size: 36px;
      margin-top: 48px;
    }
  }
`;

export const ThreeSectionTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 0;
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: column-reverse;
    & > div:first-of-type {
    }
  }
`;

export const ThreeSectionOneContent = styled(Centered)`
  padding: 48px 96px 96px 96px;
  text-align: left;
  @media (max-width: ${mobileBreakpoint}) {
    padding: 24px 48px 16px 0;
    margin-top: 72px;
  }
`;

export const ImageLink = styled.a`
  display: flex;
  position: relative;
`;

export const OverlayUnavailability = styled.div`
  background: rgba(255, 255, 255, 0.65);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  color: #111;
  justify-content: center;
  font-size: min(3.5vw, 52px);
  padding: 0 8px;
  text-align: center;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 5vw;
  }
`;

export const NavigationButton = styled.button`
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  direction: ${({ direction }) => direction};
  border: 0;
  color: ${({ color }) => color || "rgb(120 113 108)"};
  &:disabled {
    cursor: initial;
  }
  &:hover {
    opacity: 0.5;
  }
  & > img {
    height: 36px;
    width: 36px;
  }
  @media (max-width: ${mobileBreakpoint}) {
    & > img {
      height: 24px;
      width: 24px;
    }
  }
  & svg {
    stroke: 1px solid #ffffff;
  }
`;
