import * as React from "react";
import { graphql } from "gatsby";
import { useMemo } from "react";
import { useLanguage } from "../contexts/languageContext";
import styled from "styled-components";
import Container from "../styles/Container";
import GatsbyImageComponent from "../components/GatsbyImage";
import parse from "html-react-parser";
import { PageFooter } from "../styles/individuals";
import { BorderedButton } from "../styles/common";
import StickySideBar from "../components/StickySideBar";
import { mobileBreakpoint } from "../styles/theme";

const Layout = styled(Container)`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  color: ${({ color }) => color};
  @media (max-width: ${mobileBreakpoint}) {
    grid-gap: 160px;
  }
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: ${({ template }) => template || "1fr 1fr"};
  height: ${({ height }) => height || "auto"};
  ${({ padding }) =>
    padding &&
    `
  padding: ${padding}px;
    `}
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    flex-direction: ${({ mobileReverse }) =>
      mobileReverse ? "column-reverse" : "column"};
    ${({ centered }) =>
      centered &&
      `
    justify-content: center;
    `}
  }
`;
const Headline = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  @media (max-width: ${mobileBreakpoint}) {
    text-align: center;
    font-size: 40px;
  }
`;

const PageHeader = styled.h1`
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  @media (max-width: ${mobileBreakpoint}) {
    text-align: center;
    font-size: 32px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
const PageContent = styled.div`
  font-size: 22px;
  text-align: center;
  line-height: 32px;
  padding: 0 16px;
  font-weight: 100;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 16px;
    padding: 0 24px;
    line-height: 24px;
    font-weight: 400;
  }
`;
const DenimPageTemplate = ({ data }) => {
  const ref = React.useRef();
  const { lang, scrollToTop, isMobile, theme } = useLanguage();
  const { copies, images, generalCopies } = useMemo(() => {
    return {
      copies: data?.allPrismicCopiesDenim.nodes?.find((h) => h.lang === lang)
        ?.data,
      images: data?.prismicDataImages.data,
      generalCopies: data?.allPrismicCopiesGeneral?.nodes?.find(
        (h) => h.lang === lang
      )?.data,
    };
  }, [data, lang]);

  if (!copies) return null;

  return (
    <Layout ref={ref} color={theme.textColor}>
      <StickySideBar copies={generalCopies} />
      <GridLayout
        padding={isMobile ? "60px 0 36px 0" : "36px 0"}
        template="60% 40%"
        mobileReverse
      >
        <GatsbyImageComponent
          gatsbyImageData={images.denim_showcase.gatsbyImageData}
          loading="eager"
        />
        <Content>
          <PageHeader>{copies.page_title}</PageHeader>
        </Content>
      </GridLayout>
      <GridLayout template="100%" height={isMobile ? "auto" : "50vh"} centered>
        <Headline>{copies.footer}</Headline>
      </GridLayout>

      <GridLayout mobileReverse>
        <Content>
          <PageHeader>{copies.jean_1_title}</PageHeader>
          <PageContent>{parse(copies.jean_1_description.html)}</PageContent>
        </Content>
        <GatsbyImageComponent
          gatsbyImageData={images.denim_1.gatsbyImageData}
        />
      </GridLayout>

      {copies.jean_2_description.html && (
        <GridLayout>
          <GatsbyImageComponent
            gatsbyImageData={images.denim_2.gatsbyImageData}
          />
          <Content>
            <PageHeader>{copies.jean_2_title}</PageHeader>
            <PageContent>{parse(copies.jean_2_description.html)}</PageContent>
          </Content>
        </GridLayout>
      )}

      {copies.jean_3_description.html && (
        <GridLayout mobileReverse>
          <Content>
            <PageHeader>{copies.jean_3_title}</PageHeader>
            <PageContent>{parse(copies.jean_3_description.html)}</PageContent>
          </Content>
          <GatsbyImageComponent
            gatsbyImageData={images.denim_3.gatsbyImageData}
          />
        </GridLayout>
      )}

      <PageFooter>
        <BorderedButton onClick={scrollToTop}>
          {generalCopies.back_to_top}
        </BorderedButton>
      </PageFooter>
    </Layout>
  );
};

export const query = graphql`
  query pageQuery(
    $pageIds: [String]
    $generalCopiesIds: [String]
    $generalImagesId: String
    $ecommerceLinkIds: [String]
  ) {
    allPrismicDataRegionEcommerce(filter: { id: { in: $ecommerceLinkIds } }) {
      nodes {
        id
        lang
        data {
          region
          links {
            label
            link
          }
        }
      }
    }
    allPrismicCopiesDenim(filter: { id: { in: $pageIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          page_title
          jean_1_title
          jean_1_description {
            html
          }
          jean_2_title
          jean_2_description {
            html
          }
          jean_3_title
          jean_3_description {
            html
          }
          footer
        }
      }
    }
    allPrismicCopiesGeneral(filter: { id: { in: $generalCopiesIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          inspire_me
          about_the_jeans
          filter
          dark_mode
          light_mode
          back_to_top
          meta: meta_denim
        }
      }
    }
    prismicDataImages(id: { eq: $generalImagesId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        denim_showcase {
          gatsbyImageData
        }
        denim_1 {
          gatsbyImageData
        }
        denim_2 {
          gatsbyImageData
        }
        denim_3 {
          gatsbyImageData
        }
      }
    }
  }
`;

export default DenimPageTemplate;
export { default as Head } from "../components/Head";
